<template>
  <div>
    <loading-component />
  </div>
</template>

<script>
export default {
    components:{
        LoadingComponent: () =>
            import(
                /* webpackChunkName: "LoadingComponent"*/ '@/components/LoadingComponent/LoadingComponent'
            ),
    },
    created() {
        window.location.assign(`${process.env.VUE_APP_TUNA_URL}/auth`);
    },
};
</script>
